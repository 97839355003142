var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-row',{class:[
    'cycle-subject',
    _vm.hasErrors || _vm.subjectIndexError == _vm.subject.id || !_vm.subject.verification ? 'error' : '',
    'ma-0',
    'mb-1',
  ]},[_c('v-col',{staticClass:"pa-0 text-left",attrs:{"cols":"5"}},[(_vm.subject.subjects.length > 0)?_c('v-icon',[_vm._v("mdi-chevron-down")]):_vm._e(),(_vm.subject.subject_id)?_c('v-icon',[_vm._v("mdi-circle-small")]):_vm._e(),_vm._v(" "+_vm._s(_vm.subject.checkCountCreditSubjects)+" "+_vm._s(_vm.subject.selective_discipline_id ? _vm.subject.selective_discipline.title : _vm.subject.title)+" ")],1),_c('v-col',{staticClass:"pa-0"},[_vm._v(" "+_vm._s(_vm.subject.hours)+" ")]),_c('v-col',{staticClass:"pa-0"},[_vm._v(" "+_vm._s(_vm.subject.practices)+" ")]),_c('v-col',{staticClass:"pa-0"},[_vm._v(" "+_vm._s(_vm.subject.laboratories)+" ")]),_c('v-col',{staticClass:"pa-0"},[_vm._v(" "+_vm._s(_vm.sumHour(_vm.subject.hours_modules, 'hour').toFixed(1))+" ")]),_c('v-col',{staticClass:"pa-0"},[_vm._v(" "+_vm._s(_vm.subject.credits)+" ")]),_c('v-col',{staticClass:"pa-0"},[_vm._v(" "+_vm._s(_vm.subject.exams ? _vm.subject.exams.map((item) => item.semester)[0] : '')+" ")]),_c('v-col',{staticClass:"pa-0 text-right"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-btn',_vm._g(_vm._b({attrs:{"disabled":_vm.readOnly || _vm.isShortPlan &&
            (_vm.item.list_cycle_id === _vm.CYCLES.PRACTICAL_TRAINING || _vm.item.list_cycle_id === _vm.CYCLES.ATTESTATION),"small":"","icon":""},on:{"click":function($event){return _vm.editSubject(_vm.subject, _vm.item)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-pencil")])],1)]}}])},[_c('span',[_vm._v("Редагувати")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-btn',_vm._g(_vm._b({attrs:{"disabled":_vm.readOnly || _vm.isShortPlan,"small":"","icon":""},on:{"click":function($event){return _vm.delSubject(_vm.subject)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-delete")])],1)]}}])},[_c('span',[_vm._v("Видалити")])]),(!_vm.subject.subject_id)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-btn',_vm._g(_vm._b({attrs:{"disabled":_vm.readOnly || _vm.isShortPlan,"small":"","icon":""},on:{"click":function($event){return _vm.addSubSubject(_vm.subject, _vm.item)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-plus")])],1)]}}],null,false,685364274)},[_c('span',[_vm._v("Додати піддисципліну")])]):_vm._e()],1)],1),_vm._l((_vm.subject.subjects),function(child,subjectIndex){return _c('SubjectItem',{key:'subject' + subjectIndex,attrs:{"parentItem":_vm.subject,"subject":child,"item":_vm.item}})})],2)
}
var staticRenderFns = []

export { render, staticRenderFns }