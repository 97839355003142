const version = '/v1';

export const API = {
  USER: version + '/user',
  LOGIN: version + '/login',
  AUTH: version + '/auth',
  // "REGISTER": version + '/register',
  LOGOUT: version + '/logout',
  COPY_PLAN: version + '/plans/copy/',
  SETTINGS: version + '/settings',
  STUDY_TERMS: version + '/study-terms',
  FORM_STUDIES: version + '/form-studies',
  FORM_ORGANIZATIONS: version + '/form-organizations',
  FORM_CONTROLS: version + '/form-controls',
  INDIVIDUAL_TASKS: version + '/individual-tasks',
  PLAN_CREATE: version + '/plans/additional-data',
  PLAN_RESTORE: version + '/plans/restore',
  PLANS: version + '/plans',
  DEPARTMENTS: version + '/departments',
  USERS: version + '/users',
  SET_USER_ROLE: version + '/set-user-role',
  FACULTIES: version + '/faculties',
  ROLES: version + '/roles',
  WORKERS: version + '/workers',
  LIST_WORKERS: version + '/list-workers',
  FACULTY_BY_WORKER: version + '/faculty-by-worker',
  SELECTIVE_DISCIPLINES: version + '/selective-discipline',
  CYCLES: version + '/cycles',
  SUBJECTS: version + '/subjects',
  PLAN_VERIFICATION: version + '/plans/verification',
  PLAN_FILTERS: version + '/plans/filters',
  PLAN_GENERATE_SHORTED_BY_YEAR: version + '/plans/short-plan',
  VERIFICATIONS: version + '/verifications',
  VERIFICATION_SUBJECT_STATUSES: version + '/verification-subject-statuses',
  VERIFICATION_CATALOG_SPECIALITY_STATUSES: version + '/verification-catalog-speciality-statuses',
  VERIFICATION_CATALOG_EDUCATIONAL_PROGRAM_STATUSES: version + '/verification-catalog-education-program-statuses',
  SPECIALIZATIONS: version + '/specializations',
  SPECIALITIES: version + '/specialities',
  EDUCATIONAL_PROGRAMS: version + '/education-programs',
  POSITIONS: version + '/positions',
  SIGNATURES: version + '/signatures',
  NOTES: version + '/notes',
  LIST_CYCLES: version + '/list-cycles',
  PROGRAMS: version + '/programs',
  USER_ACTIVITY: version + '/user-activity',
  EDUCATION_LEVEL: version + '/education-levels',
  EDUCATION_LEVEL_RESTORE: version + '/education-levels/restore',
  SUBJECT_HELPERS: version + '/subject-helpers',
  CATALOG_HELPER_TYPES: version + '/catalog-helper-types',
  SUBJECT_LANGUAGES: version + '/subject-languages',
  CATALOG_GROUPS: version + '/catalog-groups',
  CATALOG_GROUPS_RESTORE: version + '/catalog-groups/restore',
  CATALOG_SELECTIVE_SUBJECTS: version + '/catalog-selective-subjects',
  CATALOG_SELECTIVE_SUBJECTS_FILTERS: version + '/catalog-selective-subjects/filters',
  CATALOG_SUBJECTS: version + '/catalog-subjects',
  CATALOG_SUBJECTS_TITLES: version + '/catalog-subjects/catalog-titles',
  EDUCATIONAL_PROGRAMS_ALL: version + '/education-programs',
  SPECIALITIES_ALL: version + '/specialities',
  CATALOG_SUBJECTS_GENERATE_PDF: version + '/catalog-subjects/generate-pdf',

  CATALOG_SPECIALTIES: version + '/catalog-specialties',
  CATALOG_SPECIALTIES_FILTERS: version + '/catalog-specialties/filters',
  CATALOG_SPECIALTIES_COPY: version + '/catalog-specialties/copy',
  SPECIALTY_SUBJECTS: version + '/speciality-subjects',
  SAVE_SPECIALITY_OWNERS: version + '/catalog-specialties/owners',
  SAVE_SPECIALITY_SIGNATURE: version + '/catalog-specialties/signature',
  CATALOG_SPECIALITY_GENERATE_PDF: version + '/catalog-specialties/generate-pdf',

  CATALOG_EDUCATION_PROGRAMS: version + '/catalog-education-programs',
  CATALOG_EDUCATION_PROGRAMS_FILTERS: version + '/catalog-education-programs/filters',
  CATALOG_EDUCATION_PROGRAMS_COPY: version + '/catalog-education-programs/copy',
  EDUCATION_PROGRAM_SUBJECTS: version + '/education-program-subjects',
  SAVE_EDUCATION_PROGRAM_OWNERS: version + '/catalog-education-programs/owners',
  SAVE_EDUCATION_PROGRAM_SIGNATURE: version + '/catalog-education-programs/signature',
  CATALOG_EDUCATION_PROGRAM_GENERATE_PDF: version + '/catalog-education-programs/generate-pdf',

  PLANS_CATALOG_PDF: version + '/plans/catalog-pdf',
  UPLOAD_HANDBOOK_PDF: version + '/upload',

  ERR: version + '/err',
};

export const ALLOWED_REQUEST_PARAMETERS = {
  GET_PLANS: {
    itemsPerPage: 'items_per_page',
    page: 'page',
    searchTitle: 'title',
    sortBy: 'sort_by',
    sortDesc: 'sort_desc',
    faculty: 'faculty',
    department: 'department',
    planId: 'id',
    archived: 'archived',
    planOrTemplate: 'planOrTemplate',
    divisionWithStatus: 'divisionWithStatus',
  },
  GET_LOGS: {
    itemsPerPage: 'items_per_page',
    page: 'page',
  },
  GET_USERS: {
    worker: 'fullName',
    role: 'role',
    faculty: 'faculty',
    department: 'department',
    itemsPerPage: 'items_per_page',
    page: 'page',
  },
  GET_SUBJECT_HELPERS: {
    itemsPerPage: 'items_per_page',
    page: 'page',
    searchTitle: 'title',
    type: 'type',
  },
  GET_CATALOG_GROUPS: {
    itemsPerPage: 'items_per_page',
    page: 'page',
  },
  GET_EDUCATION_LEVEL: {
    itemsPerPage: 'items_per_page',
    page: 'page',
  },
  GET_CATALOG_SELECTIVE_SUBJECTS: {
    itemsPerPage: 'items_per_page',
    page: 'page',
    year: 'year',
    group: 'group',
    faculty: 'faculty',
    department: 'department',
    divisionWithStatus: 'divisionWithStatus',
  },
  GET_CATALOG_SELECTIVE_SUBJECTS_PDF: {
    year: 'year',
    group: 'group_id',
    faculty: 'faculty',
    department: 'department',
  },
  GET_CATALOG_SUBJECTS: {
    itemsPerPage: 'items_per_page',
    page: 'page',
    year: 'year',
    group: 'group',
  },
  GET_CATALOG_SPECIALTIES: {
    itemsPerPage: 'items_per_page',
    page: 'page',
    year: 'year',
    speciality: 'speciality',
    faculty: 'faculty',
    department: 'department',
    divisionWithStatus: 'divisionWithStatus',
  },
  SPECIALTY_SUBJECTS: {
    itemsPerPage: 'items_per_page',
    page: 'page',
    year: 'year',
    speciality: 'speciality',
    faculty: 'faculty',
    department: 'department',
    catalogSubject: 'catalogSubject',
    divisionWithStatus: 'divisionWithStatus',
  },

  GET_CATALOG_EDUCATION_PROGRAMS: {
    itemsPerPage: 'items_per_page',
    page: 'page',
    year: 'year',
    education_program: 'educationProgram',
    faculty: 'faculty',
    department: 'department',
    divisionWithStatus: 'divisionWithStatus',
  },
  EDUCATION_PROGRAM_SUBJECTS: {
    itemsPerPage: 'items_per_page',
    page: 'page',
    year: 'year',
    education_program: 'educationProgram',
    faculty: 'faculty',
    department: 'department',
    catalogSubject: 'catalogSubject',
    divisionWithStatus: 'divisionWithStatus',
  },
  GET_DUPLICATE: {
    id: 'id',
    year: 'year',
    speciality_id: 'speciality_id',
    education_program_id: 'education_program_id',
    study_term_id: 'study_term_id'
  },
};
