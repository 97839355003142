var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',[_c('plans-table',{attrs:{"items":_vm.items,"meta":_vm.meta},on:{"update":_vm.getPlansList,"copy":_vm.apiCopyPlan,"edit":_vm.apiEditPlan,"delete":_vm.apiDeletePlan,"restore":_vm.apiRestore}}),(_vm.allowedRoles(
    [
      _vm.ROLES.ID.admin,
      _vm.ROLES.ID.root,
      _vm.ROLES.ID.training_department,
      _vm.ROLES.ID.practice_department,
      _vm.ROLES.ID.educational_department_deputy,
      _vm.ROLES.ID.educational_department_chief
    ]
  ))?_c('v-tooltip',{attrs:{"left":"","color":"info"},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-fab-transition',[_c('v-btn',_vm._g(_vm._b({attrs:{"color":"primary","dark":"","fixed":"","bottom":"","right":"","fab":"","to":{ name: 'CreatePlan' }}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-plus")])],1)],1)]}}],null,false,279808868)},[_c('span',[_vm._v("Створити план")])]):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }